<template>
  <Loading
    v-if="isLoading" />
  <div  v-else class="main">
    <h2 class="subtitle">Elige los servicios que desees para los alumn@s</h2>
    <div>
      <div v-for="student in students" :key="student.id">
        <div v-if="student.periods.length !== 0">
          <h3>{{ student.student.name }} {{ student.student.surnames }}</h3>
          <div class="periods-wrapper">
            <div class="student-wrapper" v-for="period in student.periods" :key="period.id">
              <div class="student-info">
                <span>Desde</span>
                <p>{{ formatDate(period.dateStart) }}</p>
              </div>
              <div class="student-info">
                <span>Hasta</span>
                <p>{{ formatDate(period.dateEnd) }}</p>
              </div>
              <div class="service-wrapper">
                <span>Elige un servicio</span>
                <div class="service">
                  <select class="form__field-input" @change="updateCheckedServices($event)">
                    <option selected value="">Selecciona un servicio</option>
                    <option
                      v-for="service in services"
                      :key="service.id"
                      :value="student.student.id+'||'+period.id+'||'+service.id+'||'+service.name+'||'+service.price+'||'+student.student.name"
                    >
                      {{ service.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="student.questionOptions.length" class="question-container">
              <span class="question-title">Permisos</span>
              <div v-for="(option, i) in picked[student.student.id]" :key="i" class="options">
                <span class="question-label">{{ option.key }}</span>
                <label class="wrapper">
                  <span>Si</span>
                  <input
                    type="radio"
                    id="yes"
                    :name="`input-${student.student.id}-${i}`"
                    :value="true"
                    @change="updateQuestionOptions($event, student.student.id, option.key)"
                    v-model="authorizedResponse[`${student.student.id}|${i}`]"
                    >
                </label>
                <label class="wrapper">
                  <span>No</span>
                  <input
                    type="radio"
                    id="no"
                    :name="`input-${student.student.id}-${i}`"
                    :value="false"
                    @change="updateQuestionOptions($event, student.student.id, option.key)"
                    v-model="authorizedResponse[`${student.student.id}|${i}`]"
                    >
                </label>
                <br />
              </div>
            </div>
            <div v-if="student.student.personsAuthorized.length !== 0" class="persons-authorized">
            <span class="authorization-title">Personas autorizadas para recoger al alumno</span>
            <Multiselect
              class="multiselect"
              v-model="personsAuthorized[student.id]"
              mode="tags"
              :searchable="true"
              :createTag="false"
              :options="student.student.personsAuthorized"
            />
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="message__noData" v-if="!hasContent">
      <div class="message__noData-icon">
        <svg width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.880469 -2.38419e-06H12.1805L10.2805 22.25H2.73047L0.880469 -2.38419e-06ZM6.53047 35.45C4.8638 35.45 3.49714 34.95 2.43047 33.95C1.3638 32.9167 0.830469 31.6667 0.830469 30.2C0.830469 28.7 1.3638 27.4667 2.43047 26.5C3.49714 25.5333 4.8638 25.05 6.53047 25.05C8.19714 25.05 9.54714 25.5333 10.5805 26.5C11.6471 27.4667 12.1805 28.7 12.1805 30.2C12.1805 31.6667 11.6471 32.9167 10.5805 33.95C9.5138 34.95 8.1638 35.45 6.53047 35.45Z" fill="white"/>
        </svg>
      </div>
      <div class="message__noData-text">
        <p>No hay alumn@s para mostrar en este campamento / taller</p>
        <p>Prueba seleccionando otro campamento / taller</p>
      </div>
    </div>
    <div v-else>
      <Switch
        v-model:checked="authorized"
        label="Acepto las condiciones de contratación de los campamentos / talleres"
      />
      <div class="description">
        <p class="text">Consulta las condiciones de contratación <a target="_blank"
                                                                    href="https://lacasitadeingles.com/condiciones-generales-de-contratacion-summer-camp/">AQUI</a>
        </p>
      </div>
      <div class="legal">
        <p><strong>Condiciones de contratación de los campamentos / talleres:</strong></p>
        <p class="text"><small>La reserva no se devuelve en el caso de cancelar o no asistir por cualquier motivo, por el perjuicio que supone dejar la plaza vacía. En caso de no asistir uno o varios días al campamento, sea cual fuere la razón, no se devolverá en ningún caso lo proporcional a los días no asistidos ni se podrá cambiar por otras actividades. La reserva se devuelve en caso de cese de actividad por parte de La Casita de Inglés por fuerza mayor. La reserva también se devuelve en el caso de que no podamos celebrar la actividad propuesta por no disponer del número mínimo de niños. En este caso se avisará a la familia con tiempo suficiente y se sugerirá una alternativa similar.</small></p>
      </div>
      <p v-if="questionsOptions.length && (servicesInfo.length && !allSelected)" class="error-message">Debes seleccionar todas las opciones donde hayas elegido un servicio.</p>
      <button @click="saveStudentsInfo" class="btn btn-save" :class="{'disabled': (questionsOptions.length && (!servicesInfo.length || !allSelected)) || !authorized}">Realizar pago</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { formatDate, formatPrice } from '@/commonMethods/methods'
import { toRaw } from 'vue'
import Switch from '../components/Switch'
import Multiselect from '@vueform/multiselect'
import Loading from './Loading.vue'

export default {
  name: 'NewCampByStudent',
  props: {
    campId: {
      type: String,
      required: true
    },
    idCenter: {
      type: String,
      required: true
    },
    questionOptionsStringify: {
      type: String,
      default: ''
    }
  },
  components: {
    Switch,
    Multiselect,
    Loading
  },
  data() {
    return {
      students: [],
      periods: [],
      services: [],
      checkedServices: [],
      servicesInfo: [],
      optionsInfo: [],
      campInfo: [],
      studentsInfo: [],
      personsAuthorized: [],
      formPersonsAuthorized: [],
      questionsOptions: [],
      campsOptionsSelected: {},
      errors: {},
      picked: [],
      authorizedResponse: {},
      authorized: false,
      isLoading: true,
      hasContent: false,
      allSelected: false
    }
  },
  computed: {
    ...mapGetters('TutorCamps', ['getCampName'])
  },
  methods: {
    formatDate,
    formatPrice,
    ...mapActions('TutorCamps', ['studentListCamps', 'periodsByCampAndStudent', 'servicesListByPeriods']),
    ...mapMutations('TutorCamps', {
      setCampInfo: 'SET_CAMP_INFO',
      setStudentsInfo: 'SET_STUDENTS_INFO'
    }),
    saveStudentsInfo() {
      for (const [key, value] of Object.entries(this.personsAuthorized)) {
        this.servicesInfo.map(student => {
          if(student.studentId === key) {
            student.personsAuthorized = value
          }
        })
      }
      this.optionsInfo.forEach(option => {
        this.campInfo.forEach((info) => {
          if (option.studentId == info.studentId) {
            info.responseToCampOptions = option.responseToCampOptions
          }
        })
      })
      this.servicesInfo.forEach(service => {
        this.campInfo.forEach(info => {
          if (service.studentId == info.studentId) {
            info.periods = service.periods
          }
        })
      })
      this.setCampInfo({ campInfo: this.campInfo, isFullPayment: this.isFullPayment })
      this.setStudentsInfo({ studentsInfo: this.studentsInfo })
      this.$router.push({ name: 'new-camp-order-summary', params: { campId: this.campId }})
    },
    updateCheckedServices() {
      this.checkedServices = []
      document.querySelectorAll('.service select').forEach(element => {
        if(element.value !== '') {
          this.checkedServices.push(element.value)
        }
      })
    },
    updateQuestionOptions(e, studentId, key) {
      this.allSelected = true
      this.optionsInfo = []
      this.picked[studentId].forEach((student, i) => {
        if (key === student.key) {
          this.picked[studentId][i].value = e.target.value === 'true'
        }
      })
      for (const studentId in this.picked) {
        let responseToCampOptions = {}
        this.picked[studentId].forEach(pick => {
          responseToCampOptions[pick.key] = pick.value
          const student = this.servicesInfo.find(student => student.studentId == studentId)
          if (student && student.studentId == studentId) {
            if (pick.value === null) {
              this.allSelected = false
            }
          }
        })
        this.optionsInfo.push({
          studentId,
          responseToCampOptions
        })
      }
    },
  },
  watch: {
    'checkedServices': function(value) {
      let elements = {}
      let students = {}
      this.servicesInfo = []
      this.studentsInfo = []
      toRaw(value).forEach(element => {
        const pieces = element.split('||')
        if (!Object.prototype.hasOwnProperty.call(elements, pieces[0])) {
          elements[pieces[0]] = []
          students[pieces[5]] = []
        }
        elements[pieces[0]].push([pieces[1], pieces[2]])
        students[pieces[5]].push([pieces[3], pieces[4]])
      })
      for (const studentId in elements) {
        let periods = []
        elements[studentId].forEach(info => {
          periods.push({
            id: info[0],
            service: info[1]
          })
        })
        this.servicesInfo.push({ studentId, periods })
        this.picked[studentId].forEach(pick => {
          if (pick.value === null) {
            this.allSelected = false
          }
        })
      }
      for (const studentName in students) {
        let student = []
        students[studentName].forEach(info => {
          student.push({
            serviceName: info[0],
            servicePrice: info[1]
          })
        })
        this.studentsInfo.push({
          studentName: studentName,
          studentInfo: student
        })
      }
    },
  },
  async mounted() {
    if(this.getCampName.length === 0) {
      this.$router.push({ name: 'new-camp-center', params: { idCenter: this.idCenter } })
    } else {
      this.servicesListByPeriods(this.campId)
      .then(response => {
        this.services = response
      })
      .catch(errors => {
        this.errors = errors
      })
      this.questionsOptions = JSON.parse(this.questionOptionsStringify)
      await this.studentListCamps(this.campId)
      .then(response => {
        response.forEach((student) => {
          this.periodsByCampAndStudent({campId: this.campId, studentId: student.id})
          .then(response => {
            this.students.push({
              student: student,
              periods: response,
              questionOptions: this.questionsOptions,
            })
            if(response.length !== 0) {
              this.hasContent = true
              this.campInfo.push({
                studentId: student.id,
                periods: [],
                responseToCampOptions: {}
              })
            }
          })
          .catch(errors => {
            this.errors = errors
          })
          .finally(() => {
            this.isLoading = false
          })
          student.personsAuthorized = student.personsAuthorized.map(item => {
            return { value: item.id, label: item.name }
          })
          this.picked[`${student.id}`] = this.questionsOptions.map(question => ({
            key: question,
            value: null
          }))
        })
      })
      .catch(errors => {
        this.errors = errors
      })
    }
  },
}
</script>

<style scoped lang="scss">
.main {
  .error-message {
    color: $error;
    font-weight: 600;
    margin-top: 0;
    text-align: center;
  }
  .text {
    margin-bottom: 3em;
  }
}
.admin__table__body__cell {
  cursor: auto;

  @include tablet {
    span {
      display: none;
    }
  }
}
.student-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 30px;
  margin-bottom: 20px;
  .student {
    display: flex;
    align-items: center;
  }
  .student-info {
    span {
      font-weight: 700;
    }
    p {
      margin-bottom: 0;
    }
  }
  @include desktop {
    margin-bottom: 8px;
    &:not(:first-child) {
      .student-info {
        span {
          display: none;
        }
      }
      .service-wrapper {
        span {
          display: none;
        }
      }
    }
  }
}
.periods-wrapper {
  padding: 20px 30px;
  background-color: #f2faf9;
  border-radius: 18px;
  margin-bottom: 20px;
  .student-wrapper {
    padding: 0;
    justify-content: space-between;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .question-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .question-title {
      font-weight: 600;
      margin-bottom: 10px;
    }
    .options {
      display: flex;
      margin: 4px 0;
      gap: 10px;
      line-height: 30px;
      .question-label {
        min-width: 16%;
      }
      .wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 6px;
        input {
          cursor: pointer;
        }
        p {
          margin: 0;
        }
      }
    }
    input {
      height: 20px;
      margin: 0;
      width: 20px;
    }
  }
}
.service-wrapper {
  span {
    font-weight: 700;
  }

  .service {
    display: flex;
    gap: 24px;
    select {
      width: 100%;
    }
  }
  .form__field-input {
    margin-top: 10px;
  }
  @include tablet {
    min-width: 300px;
  }
}
.form-check-label {
  display: flex;
  align-items: flex-end;
}
.btn {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  &.disabled {
    background-color: $grey;
    border: 1px solid $grey;
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      background-color: $grey;
      border: 1px solid $grey;
      color: $white;
    }
  }
}
.legal {
  p {
    &:first-child {
      text-transform: uppercase;
    }
  }
}
.multiselect {
  margin: 0;
  width: 40%;
}
.persons-authorized {
  display: flex;
  flex-direction: column;
}
.authorization-title {
  font-weight: 600;
}
</style>
