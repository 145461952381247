<template>
  <Loading v-if="isLoading" />
  <div v-else class="parents-wrapper" :class="{ 'scroll-block': isPaymentModalOpen }">
<!--    <div v-if="getUserInfo && getUserInfo.isSwitchTutor" class="switch-btn-wrapper">-->
<!--      <button @click="backToAdminSide" class="btn">Volver al admin</button>-->
<!--    </div>-->
    <Sidebar
      @on-visible-menu="visibleMenu"
      @onOpenModal="openModal"
    />
    <div class="content" :class="{'content--visible_menu': showSidebar}" >
      <div class="errors" v-for="(error, key) in errors" :key="key">
        <p>{{ $t(error) }}</p>
      </div>
      <router-view></router-view>
    </div>
    <div v-if="isPopupOpen || isCardChange || changingPaymentMethodModal"
         class="content__popup__wrapper"
         @click.self="setPopupStatus(false)">
      <div class="content__popup">
        <img  src="../assets/missed_newBlue.svg"
              alt="close-popup"
              class="content__popup__close-image"
              @click="setPopupStatus(false)">
        <div v-if="isCardChange || changingPaymentMethodModal" class="change-card">
          <template v-if="changingPaymentMethodModal">
            <label class="form__field-label" for="#staffMember">Método de pago seleccionado:</label>
            <input class="payment-method-input" type="text" disabled :value="`${paymentMethodSelected.center.name} - expiración de la tarjeta ${paymentMethodSelected.expiryDate}`">
          </template>
          <template v-else>
            <label class="form__field-label" for="#staffMember">Selecciona el método de pago</label>
            <select
              v-model="paymentSelected"
              class="form__field-input change-card__select"
              name="payment"
              id="payment">
              <option v-for="(payment,idx) in paymentMethods" :key="idx" :value="payment.value">{{payment.label}}</option>
            </select>
          </template>
        </div>
        <div class="content__popup__text content__popup__text--header">
          {{isCardChange || changingPaymentMethodModal ? 'Por favor, acepta las condiciones de contratación para modificar el método de pago de forma segura' : 'Por favor, introduce un método de pago para automatizar los pagos de la extraescolar en curso'}}
        </div>
        <Switch
          class="content__popup__switch"
          v-model:checked="isConditionAccepted"
          label="Acepto las condiciones de contratación" />
        <div class="legal">
          <p><strong>Condiciones de contratación</strong></p>
          <p>
            <small>
              Antes de aceptar las condiciones generales de contratación, por favor leedlas detenidamente en:
            </small>
            <a class="content__popup__link" href="https://lacasitadeingles.com/condiciones-generales-de-contratacion/">
              https://lacasitadeingles.com/condiciones-generales-de-contratacion/
            </a>
          </p>
        </div>
        <div class="message content__popup__check">
          <div class="check">
            <img class="check__img content__popup__check__image" alt="check" src="../assets/check.png">
          </div>
          <p class="content__popup__text"><strong>Nuestro pago es 100% seguro y está protegido por Redsys.</strong> Almacenaremos tus datos de pago para automatizar el cobro de las cuotas de este curso y siempre podrás ver en tu perfil cualquier cargo que te hayamos realizado.</p>
        </div>
        <div class="errors content__popup__error">
          <p>{{ paymentError }}</p>
        </div>
        <button
            class="btn content__popup__button"
            :class="{disabled: !isConditionAccepted}"
            type="text"
            @click="goToSavePaymentMethod">{{isCardChange || changingPaymentMethodModal ? 'Cambiar método de pago'  : 'Insertar método de pago'}}
        </button>
      </div>
    </div>
    <PaymentMethodModal
      v-if="user && user.askSwitchToCreditCard && isPaymentModalOpen"
      @handlerModal="handlerPaymentMethodModal"
      @click.self="handlerPaymentMethodModal(false)"
    />
    <div>
      <form id="redsys-form" method="post" action="url">
        <input type="hidden" name="Ds_MerchantParameters"/>
        <input type="hidden" name="Ds_SignatureVersion"/>
        <input type="hidden" name="Ds_Signature"/>
      </form>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Sidebar from '../components/Sidebar.vue'
import Loading from '../components/Loading.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import Footer from '../components/Footer.vue'
import Switch from '../components/Switch.vue'
import PaymentMethodModal from '../components/PaymentMethodModal.vue'
// import { apiClient } from '../services/ApiClient'

export default {
  name: 'Parents',
  components: {
    Sidebar,
    Loading,
    Footer,
    Switch,
    PaymentMethodModal
  },
  data() {
    return {
      bodyPage: document.querySelector('body'),
      showSidebar: false,
      isLoading: false,
      isConditionAccepted: false,
      isPopupOpen: false,
      isCardChange: false,
      isPaymentModalOpen: false,
      errors: null,
      paymentSelected: null,
      paymentError: '',
      paymentMethods: []
    }
  },
  computed: {
    ...mapGetters('User', {
      tutor: 'getUser'
    }),
    ...mapGetters('Admin', ['getUserInfo']),
    ...mapState('User', ['user', 'changingPaymentMethodModal', 'paymentMethodSelected'])
  },
  watch: {
    tutor: {
      immediate: true,
      handler(newTutor) {
        if (newTutor) {
          if (this.$route.name === 'parents-main-screen') {
            this.setPopupStatus(newTutor.requirePaymentMethod)
          }
        }
      }
    },
    changingPaymentMethodModal: {
      immediate: true,
      handler(value) {
        if (value && this.paymentMethodSelected) {
          this.paymentSelected = this.paymentMethodSelected.center.id
          this.openModal()
        }
      }
    }
  },
  methods: {
    ...mapActions('User', ['fetchProfile']),
    ...mapActions('Admin', ['userInfo', 'handleSwitchUser']),
    ...mapActions('Students', ['savePaymentMethod', 'redsysPaymentMethods']),
    ...mapActions('User', ['handlerPaymentMethod']),
    visibleMenu() {
      this.showSidebar = !this.showSidebar
    },
    async goToSavePaymentMethod() {
      if (this.isConditionAccepted) {
        try {
          const response = await this.savePaymentMethod(this.isCardChange ? { center: this.paymentSelected } : {})
          const redsysForm = document.getElementById('redsys-form')
          redsysForm.action = response.data.url
          redsysForm.elements['Ds_MerchantParameters'].value = response.data.Ds_MerchantParameters
          redsysForm.elements['Ds_SignatureVersion'].value = response.data.Ds_SignatureVersion
          redsysForm.elements['Ds_Signature'].value = response.data.Ds_Signature
          redsysForm.submit()
        } catch (error) {
          this.paymentError = 'Lo sentimos, no hemos podido conectar con la pasarela de pago, inténtelo de nuevo.'
        }
      }
    },
    setPopupStatus (status, isOpenModal) {
      this.isPopupOpen = status
      if (status) {
        this.bodyPage.classList.add('scroll-blocked')
      } else {
        this.bodyPage.classList.remove('scroll-blocked')
      }
      if (!isOpenModal) {
        this.isCardChange = false
        this.handlerPaymentMethod(false)
        this.paymentSelected = null
      }
    },
    async openModal() {
      try {
        const response = await this.redsysPaymentMethods()
        this.paymentMethods = response.data.map(payment => ({
          value: payment.center.id,
          label: `${payment.center.name} - Expiración de la tarjeta ${payment.expiryDate}`
        }))
        this.isCardChange = true
        this.setPopupStatus(true, true)
        window.scrollTo(0, 0)
      } catch(error) {
        console.log(error)
      }
    },
    handlerPaymentMethodModal(value) {
      this.isPaymentModalOpen = value
      this.bodyPage.classList.remove('scroll-blocked')
    },
    async backToAdminSide() {
      const response = await this.handleSwitchUser({
        route: 'admin-main-screen',
        endpoint: '/tutor/exit-impersonation'
      })

      if (response) {
        this.$toast.error('Ha ocurrido un error, inténtelo de nuevo.')
      }
    }
  },
  beforeMount() {
    this.isLoading = true
    this.fetchProfile()
      .then(response => {
        if (response.askSwitchToCreditCard) {
          this.isPaymentModalOpen = true
          this.bodyPage.classList.add('scroll-blocked')
        }
        this.isLoading = false
      })
      .catch(errors => {
        this.isLoading = true
        this.errors = errors
      })

    this.userInfo()
  }
}

</script>

<style lang="scss">
.parents-wrapper {
  background-color: #f2f2f2;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}
.scroll-block {
  overflow-y: hidden;
}
.content {
  min-height: calc(100vh - 80px);
  padding: 60px 15px 20px;
  transition: all .8s ease;

  &__popup {
    background-color: $white;
    border-radius: 10px;
    border: 1px solid $bg-green;
    color: $newDark;
    left: 50%;
    max-width: 350px;
    padding: 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    &__wrapper {
      background-color: rgba($bg-btn, 0.7);
      bottom: 0;
      height: 100vh;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 5;
    }

    &__close-image {
      height: 28px;
      padding: 16px;
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;

      &:hover {
        cursor: pointer;
      }
    }

    &__legal {
      color: $white;
    }

    &__switch {
      margin-top: 8px;
    }

    &__link {
      color: $bg-btn-active;
      font-size: 13px;
      text-decoration: none;
    }

    &__check {
      display: flex;

      &__image {
        height: 25px;
        margin-right: 8px;
        width: 25px;
      }
    }

    &__text {
      margin-top: 0;

      &--header {
        margin-top: 24px;
      }
    }

    &__error {
      margin-bottom: 16px;
      text-align: center;
    }

    &__button {
      margin-top: 8px;
      width: 100%;
    }
    .payment-method-input {
      margin-top: 6px;
      padding: 10px 6px;
      min-width: 90%;
    }
  }
  .main {
    padding: 15px;
    min-height: calc(100vh - 110px);
    background-color: white;
    border-radius: 22px;
    .subtitle:first-child {
      margin-top: 0;
    }
    .text {
      margin-bottom: 3em;
    }
  }
  @include tablet {
    min-height: calc(100vh - 40px);
    padding: 20px;
    padding-left: 360px;
    .main {
      padding: 30px;
      min-height: calc(100vh - 100px);
    }
  }
}
.change-card {
  margin-top: 16px;

  &__select {
    width: 100%;
  }
}
.btn {
  &.disabled {
    background-color: $grey;
    border: 1px solid $grey;
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      background-color: $grey;
      border: 1px solid $grey;
      color: $white;
    }
  }
}

.switch-btn-wrapper {
  display: none;
}

@include tablet {
  .switch-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0 40px;
    margin-top: 30px;
    cursor: pointer;
  }
}
</style>
